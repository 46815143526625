@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: theme('borderColor.DEFAULT', currentColor);
  }
  .text-heading-xl {
    @apply text-[30px] font-medium sm:text-[42px];
  }
  .text-heading-large {
    @apply text-[24px] font-medium sm:text-[30px];
  }
  .text-tag {
    @apply text-[12px] font-medium uppercase;
  }
  .loading-base {
    @apply !bg-smoke-dark bg-none !text-smoke-dark;
  }
  .loading-darker {
    @apply !bg-smoke-darker bg-none !text-smoke-darker;
  }
}

@layer components {
  .btn-primary {
    @apply !border-purple-darker !bg-purple-dark !text-white hover:!bg-purple disabled:!border-smoke-darker disabled:!bg-smoke disabled:!text-slate-lighter;
  }
  .btn-secondary {
    @apply !border-slate !bg-white !text-slate hover:!border-slate hover:!bg-smoke disabled:!bg-smoke disabled:!text-slate-lighter;
  }

  .thin-scrollbar {
    scrollbar-width: thin;
  }

  .hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

*:focus:not(:focus-visible),
html:not([data-headlessui-focus-visible]) *:focus {
  outline: none !important;
}
